<template>
    <modal show="show" :modalLarge="true" v-if="isValid('CanAddColor') || isValid('CanEditColor')">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title m-0" id="exampleModalDefaultLabel">Contractor</h6>
                <button type="button" class="btn-close" v-on:click="close()"></button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="printInvoice()">Print</button>
                        <button type="button" class="btn btn-soft-primary btn-sm" v-on:click="Downlaod">Download</button>

                    </div>
                </div>
                <div class="row" id="printRecord" ref="printRecord">
                    <div class="col-sm-12">
                        <h3>{{ contractor.name }}</h3>
                    </div>
                    <div class="col-sm-6">
                        <h3>Preapred For:{{ contractor.preparedFor }}</h3>
                    </div>
                    <div class="col-sm-12" v-html="contractor.description"></div>
                    <!-- <div class="col-sm-12"  style="float: right;" >
                        <AddProductImage v-on:picPath="getImage" v-bind:path="image" />

                    </div> -->


                </div>


            </div>
            <iframe ref="printFrame" style="display: none;"></iframe>

        </div>


    </modal>
</template>

<script>
import clickMixin from '@/Mixins/clickMixin'
import html2pdf from 'html2pdf.js';
export default {
    mixins: [clickMixin],
    props: ['show', 'contractor','selectedCutomer'],
    components: {

    },
    data: function () {
        return {


        }
    },

    methods: {
        GetRecord: function (selectedOption) {
            alert('amb')
            console.log(selectedOption)
            // Assuming selectedCustomer contains the object of the selected customer
            const selectedCustomer = selectedOption /* Logic to fetch the selected customer object */;

            // Get the reference to the VueEditor
            const editor = this.$refs.editor;

            // Get the current content of the VueEditor
            const editorContent = editor.quill.getContents();

            // Loop through each key in the selected customer object
            for (const key in selectedCustomer) {
                if (Object.hasOwnProperty.call(selectedCustomer, key)) {
                    const regex = new RegExp(`\\b${key}\\b`, 'g'); // Create a regex to match whole words
                    const replacement = selectedCustomer[key];

                    // Replace occurrences of the key with the corresponding value in the editor content
                    editorContent.ops.forEach(op => {
                        if (typeof op.insert === 'string' && regex.test(op.insert)) {
                            op.insert = op.insert.replace(regex, replacement);
                        }
                    });
                }
            }

            // Set the updated content back to the VueEditor
            editor.quill.setContents(editorContent);
        },
        Downlaod() {
            debugger;

      const printRecordContent = this.$refs.printRecord.innerHTML;

      html2pdf().from(printRecordContent).save(); // Generate and save PDF
    },
       
        printInvoice() {
            debugger;
            let printContents = `
                        <html>

                        <head>
                            <title>Ticket Invoice</title>
                        </head>
                        <body>
                            ${this.$refs.printRecord.innerHTML}
                        </body>
                        </html>
                    `;

            let printFrame = this.$refs.printFrame;

            if (printFrame) {
                printFrame.contentDocument.body.innerHTML = printContents;
                printFrame.contentWindow.print();
            } else {
                // Handle if iframe reference is not available
            }
        },
        close: function () {
            this.$emit('close');
        },


    },
    mounted: function () {
        console.log('amb')
        console.log(this.selectedCutomer)
        this.GetRecord(this.selectedCutomer);

    },

}
</script>
<style >
.ql-align-center {
text-align: center !important;
}
.ql-align-right {
text-align: right !important;
}
.ql-align-justify {
text-align: justify !important;
}
.ql-indent-1 {
  padding-left: 20px; /* Adjust this as needed */
}
.ql-indent-2 {
  padding-left: 40px; /* Adjust this as needed */
}
.ql-indent-3 {
  padding-left: 60px; /* Adjust this as needed */
}
.ql-indent-4 {
  padding-left: 80px; /* Adjust this as needed */
}
.ql-indent-5 {
  padding-left: 100px; /* Adjust this as needed */
}
.ql-indent-6 {
  padding-left: 120px; /* Adjust this as needed */
}
.ql-indent-7 {
  padding-left: 140px; /* Adjust this as needed */
}
.ql-indent-8 {
  padding-left: 160px; /* Adjust this as needed */
}
</style>


